import React from 'react';
import { Spacing, Icon, Text } from '@reservamos/elements';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const DescriptionIcon = ({
  icon,
  children,
  title,
  sizeIcon,
  titleSize,
  titleWeight,
  hasBorder,
  hasWidth,
  isCentered,
}) => {
  const descriptionContainer = css`
    border-bottom: ${hasBorder ? '1px solid #dfdfdf' : 'none'};
    padding-bottom: ${hasBorder ? '20px' : 'none'};
    ${hasWidth && 'min-width: 260px'};
  `;

  return (
    <div css={descriptionContainer}>
      <Spacing vertical size="S">
        <Spacing vertical={isCentered} size="S" alignItems="center">
          <Icon type={icon} size={sizeIcon} />
          <Text
            size={titleSize}
            color="primaryStrong"
            weight={titleWeight}
            textAlign={isCentered ? 'center' : null}
          >
            {title}
          </Text>
        </Spacing>
        {children}
      </Spacing>
    </div>
  );
};

DescriptionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sizeIcon: PropTypes.string,
  titleSize: PropTypes.string,
  titleWeight: PropTypes.string,
  hasBorder: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasWidth: PropTypes.bool,
  isCentered: PropTypes.bool,
};

DescriptionIcon.defaultProps = {
  sizeIcon: 'XL',
  titleSize: 'L',
  titleWeight: 'bold',
  hasBorder: false,
  hasWidth: false,
  isCentered: false,
};

export default DescriptionIcon;
