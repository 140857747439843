import React from 'react';
import { Modal, Spacing, Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import DescriptionIcon from '../molecules/DescriptionIcon';
import assetUrl from '../../utils/assetUrl';

const CovidModal = ({
  modalTrigger,
  modalTitle,
  faceMaskTitle,
  faceMaskDescription,
  socialDistancingTitle,
  socialDistancingDescription,
  handSanitizerTitle,
  handSanitizerDescription,
  sanitizedUnitTitle,
  sanitizedUnitDescription,
}) => {
  return (
    <Modal
      modalTrigger={modalTrigger}
      responsiveSize="L"
      title={modalTitle}
      closeIcon="ClosePrimary"
      titleColor="primaryStrong"
    >
      <Spacing vertical size="L">
        <DescriptionIcon
          icon={assetUrl('icons/covid/facemask.webp')}
          title={faceMaskTitle}
        >
          <Text size="S">{faceMaskDescription}</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/distance.webp')}
          title={socialDistancingTitle}
        >
          <Text size="S">{socialDistancingDescription}</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/sanitizer.webp')}
          title={handSanitizerTitle}
        >
          <Text size="S">{handSanitizerDescription}</Text>
        </DescriptionIcon>
        <DescriptionIcon
          icon={assetUrl('icons/covid/cleaning.webp')}
          title={sanitizedUnitTitle}
        >
          <Text size="S">{sanitizedUnitDescription}</Text>
        </DescriptionIcon>
      </Spacing>
    </Modal>
  );
};

CovidModal.propTypes = {
  modalTrigger: PropTypes.node.isRequired,
  modalTitle: PropTypes.string.isRequired,
  faceMaskTitle: PropTypes.string.isRequired,
  faceMaskDescription: PropTypes.string.isRequired,
  socialDistancingTitle: PropTypes.string.isRequired,
  socialDistancingDescription: PropTypes.string.isRequired,
  handSanitizerTitle: PropTypes.string.isRequired,
  handSanitizerDescription: PropTypes.string.isRequired,
  sanitizedUnitTitle: PropTypes.string.isRequired,
  sanitizedUnitDescription: PropTypes.string.isRequired,
};

export default CovidModal;
