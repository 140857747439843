import React from 'react';
import { Link } from '@reservamos/elements';
import PropTypes from 'prop-types';
import FaqModal from './FaqModal';

const Question = ({ titleLabel }) => {
  return (
    <>
      {process.env.NEXT_PUBLIC_BRAND === 'vanda' && <FaqModal />}
      {process.env.NEXT_PUBLIC_BRAND === 'roll-bits' && (
        <Link
          text={titleLabel}
          type="accent"
          href="https://roll-bits.com/preguntas-frecuentes/"
          newTab
          size="M"
        />
      )}
    </>
  );
};

Question.propTypes = {
  titleLabel: PropTypes.string.isRequired,
};

export default Question;
