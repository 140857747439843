import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Spacing } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import Container from '../atoms/Container';

const Message = ({
  title,
  icon,
  textMessage,
  children,
  isCompact,
  sizeIcon,
  iconType,
  tooltipMessage,
}) => {
  const { colors } = useTheme();

  const tooltip = css`
    position: relative;
    cursor: pointer;

    &:hover .tooltipContent {
      opacity: 1;
    }
  `;

  const tooltipContent = css`
    position: absolute;
    background-color: ${colors.primaryStrong};
    padding: 10px;
    min-height: 100%;
    min-width: 300px;
    z-index: 1;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    border-radius: 5px;
    bottom: -100px;
    right: -5px;
    opacity: 0;
    ::after {
      position: absolute;
      top: -20px;
      right: 5px;
      content: '▲';
      color: ${colors.primaryStrong};
      font-size: 25px;
    }
  `;

  return (
    <Container smallPadding>
      <Spacing
        size={isCompact ? 'M' : 'S'}
        vertical={!isCompact}
        isResponsive
        responsiveSize={isCompact ? 'XS' : ''}
      >
        <Spacing size="S" alignItems="center">
          <Icon type={icon} size={sizeIcon} />
          <Text color="primaryStrong" weight="bold">
            {title}
          </Text>

          {Boolean(iconType) && (
            <div css={tooltip}>
              <Icon type={iconType} size="S" />

              <div css={tooltipContent} className="tooltipContent">
                <Text size="S" color="white">
                  {tooltipMessage}
                </Text>
              </div>
            </div>
          )}
        </Spacing>
        {Boolean(textMessage) && (
          <Spacing alignItems="center">
            <Text size={isCompact ? 'XS' : 'S'}>{textMessage}</Text>
          </Spacing>
        )}
        {Boolean(children) && <Spacing alignItems="center">{children}</Spacing>}
      </Spacing>
    </Container>
  );
};

Message.propTypes = {
  /** Icon */
  icon: PropTypes.string,
  /** Title message */
  title: PropTypes.string.isRequired,
  /** Message text */
  textMessage: PropTypes.string,
  /** Container's content */
  children: PropTypes.node,
  /** Indicates if Message is compact */
  isCompact: PropTypes.bool,
  sizeIcon: PropTypes.string,
  iconType: PropTypes.string,
  tooltipMessage: PropTypes.string,
};

Message.defaultProps = {
  sizeIcon: 'L',
  icon: '',
  textMessage: '',
  children: null,
  isCompact: false,
  iconType: '',
  tooltipMessage: '',
};

export default Message;
